import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { CompanyService } from '../../services/company.service';

export const redirectToAccountIdRouteGuard: CanActivateFn = () => {
  const accountsService = inject(CompanyService);
  const router = inject(Router);

  return accountsService.getAccounts$.pipe(map((accounts) => router.createUrlTree([`/company/${accounts[0].guid}`])));
}