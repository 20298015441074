import { Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import { AccountWithoutManager } from 'src/app/register-company-manager/models/account-without-manager';
import { AccountWithoutManagerGuid } from 'src/app/review-company-manager/models/account-with-manager';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mgt-company-card[company]',
  templateUrl: './company-card.component.html',
  imports: [MatIcon, TranslateModule],
  styleUrls: ['./company-card.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CompanyCardComponent {
  protected readonly company = input.required<AccountWithoutManager | AccountWithoutManagerGuid>();
}
