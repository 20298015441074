<ct-platforms-chip-container>

  @for (platform of platforms; track platform) {
  <ct-platform-chip
    [class.platform-manager]='platform.isManager'
    [class]='platform.name.toLowerCase() + "-background-color"'
  >
    {{ rsSkinPrefix + "." + platform.name | translate }}

    @if (platform.isManager) {
    <ng-container>
      <mat-icon
        data-testid='platform-chip-king-mat-icon'
        svgIcon='king'
      />
    </ng-container>
    }
  </ct-platform-chip>
  }
</ct-platforms-chip-container>