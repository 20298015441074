/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { NgModule } from '@angular/core';
import { ResolveFn, RouterModule, Routes } from '@angular/router';
import { InsightsUserOnlyGuard } from '@core/guards/InsightsUserOnly.guard';

import {
  redirectToAccountIdRouteGuard
} from '../company/guards/redirect-to-account-id-route/redirect-to-account-id-route.guard';
import { CompanySectionResolver } from '../company/resolvers/company-section/companySectionResolver';
import { CompanySectionDataset, CompanySectionDatasetResolver } from '../company/models/company-section-dataset';
import { isFeatureAllowed } from '../shared/guards/feature.guard';

export const MGT_ROUTES: Routes = [
  {
    path: 'pricing',
    loadChildren: () => import('../pricing/pricing.module')
      .then((module) => module.PricingModule),
    data: {
      title: 'Renta Solutions MGT - Pricing Simulator'
    }
  },
  {
    path: 'company',
    canActivate: [isFeatureAllowed('company'), redirectToAccountIdRouteGuard],
    pathMatch: 'full',
    children: []
  },
  {
    path: 'company/:accountGuid',
    canActivate: [isFeatureAllowed('company')],
    resolve: ({ companySectionDataset: CompanySectionResolver } as CompanySectionDatasetResolver<ResolveFn<CompanySectionDataset>>),
    loadComponent: () => import('../company/company-layout.component').then((component) => component.CompanyLayoutComponent),
    data: {
      title: 'Renta Solutions MGT - Company'
    },
    children: [
      {
        path: 'rs-accounts',
        loadComponent: () => import('../company/components/rs-accounts/rs-accounts.component').then((component) => component.RsAccountsComponent),
        data: {
          title: 'Renta Solutions MGT - Company Overview'
        }
      },
      {
        path: 'invoices',
        loadComponent: () => import('../company/components/invoices/invoices.component').then((component) => component.InvoicesComponent),
        data: {
          title: 'Renta Solutions MGT - Company Invoices'
        }
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'rs-accounts'
      }
    ]
  },
  {
    path: 'users',
    loadChildren: () => import('../users/users.module')
      .then((module) => module.UsersModule),
    canActivate: [InsightsUserOnlyGuard],
    data: {
      title: 'Renta Solutions MGT - Users'
    }
  },
  {
    path: 'portal',
    loadChildren: () => import('../my-profile-and-settings/my-profile-and-settings-section.module')
      .then((module) => module.MyProfileAndSettingsSectionModule),
    data: {
      title: 'Renta Solutions MGT - My Profile'
    }
  },
  {
    path: 'register-company-manager',
    loadChildren: () => import('../register-company-manager/register-company-manager.module')
      .then((module) => module.RegisterCompanyManagerModule),
    data: {
      title: 'Renta Solutions MGT - Register Company Manager'
    }
  },
  {
    path: 'review-company-manager/:id',
    loadComponent: () => import('../review-company-manager/review-company-manager.component')
      .then((component) => component.ReviewCompanyManagerComponent),
    data: {
      title: 'Renta Solutions MGT - Review Company Manager',
      hideTopBar: true,
    }
  },
  {
    path: 'insights',
    children: [
      {
        path: ':groupName/:groupId',
        pathMatch: 'full',
        loadComponent: () => import('../insights-bi-reports/table-list/table-list.component').then((component) => component.TableListComponent),
        data: {
          title: 'Renta Solutions MGT - Insights List'
        }
      },
      {
        path: ':groupName/:groupId/report/:reportId',
        loadComponent: () => import('../insights-bi-reports/report/report.component').then((component) => component.ReportComponent),
        data: {
          title: 'Renta Solutions MGT - Insights Report'
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'users'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(MGT_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
