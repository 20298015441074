import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserAccesses } from '../models/user-accesses';
import { AppService } from '../../app/services/app/app.service';

export function isFeatureAllowed(
    featureName: keyof UserAccesses,
    redirectUrl?: string
): CanActivateFn {
  return () => {
    const appService = inject(AppService);
    const router = inject(Router);

    return appService.getUserAccesses().pipe(
      map((accesses) => (accesses[featureName] ? true : router.parseUrl(redirectUrl || '/')))
    );
  };
}
