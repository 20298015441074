import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { RsLoaderService } from '@lib/rs-ngx';
import { CompanyService } from '../../services/company.service';
import { finalize } from 'rxjs/operators';
import { CompanySectionDataset } from '../../models/company-section-dataset';


export const CompanySectionResolver: ResolveFn<CompanySectionDataset> = (route: ActivatedRouteSnapshot) => {
  const accountsService = inject(CompanyService);
  const spinner = inject<RsLoaderService>(RsLoaderService);
  const accountGuid = route.paramMap.get('accountGuid');

  spinner.show({ delay: 600 });

  if (!accountGuid) {
    throw new Error('Account GUID is required');
  }

  return accountsService.loadCompanySkeletonData$(accountGuid!).pipe(finalize(() => spinner.hide()));
};