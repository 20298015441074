<rs-card profile-card>
  <ct-company-global-info>
    <ct-logo-circle>
      <mat-icon svgIcon="company" />
    </ct-logo-circle>

    <ct-company-name
      class="mb-5px text-center"
      data-testid="profile-card-company-name"
    >
      {{ company().name }}
    </ct-company-name>

    <ct-vat-number data-testid="profile-card-vat-number">
      {{ company().vatNumber }}
    </ct-vat-number>
  </ct-company-global-info>

  <ct-company-adress-info data-testid="profile-card-company-address">
    {{ company().address.street }}
    {{ company().address.houseNumber }}
    <br />
    {{ company().address.postalCode }}
    {{ company().address.city }}
    <br />
    {{ 'COUNTRIES.' + company().address.country | translate }}
  </ct-company-adress-info>
</rs-card>