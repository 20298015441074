import { computed, inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Account } from '../../shared/models/account';
import { Email, UUID } from '@lib/rs-ngx';
import { InvoicesSummary } from '../../shared/models/invoices-summary';
import { CompanySectionDataset } from '../models/company-section-dataset';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private readonly http = inject(HttpClient);
  private readonly refreshAccounts = new BehaviorSubject<void>(undefined);

  // Private state signals
  private readonly _accounts = signal<Account[]>([]);
  private readonly _currentAccountGuid = signal<string | null>(null);
  private readonly _invoiceSummary = signal<InvoicesSummary>({
    amountInvoicesDue: 0,
    amountTotalDue: 0
  });

  // Computed properties to access state
  public readonly accounts = computed(() => this._accounts());
  public readonly currentAccountGuid = computed(() => this._currentAccountGuid());
  public readonly invoiceSummary = computed(() => this._invoiceSummary());
  public readonly selectedAccount = computed(() => {
    return this.currentAccountGuid() && this.accounts().length ?
      this.accounts().find((account) => account.guid === this.currentAccountGuid())! :
      null;
  });
  public readonly onAccountGuidUrlUpdate = new Subject<string>();

  private setAccounts(accounts: Account[]): void {
    this._accounts.set(accounts);
  }

  private setCurrentAccountGuid(guid: string): void {
    this._currentAccountGuid.update(prevGuid => {
      if ( prevGuid && prevGuid !== guid ) { this.onAccountGuidUrlUpdate.next(guid) }
      return guid;
    });
  }

  private setInvoiceSummary(invoiceSummary: InvoicesSummary): void {
    this._invoiceSummary.set(invoiceSummary);
  }

  public loadCompanySkeletonData$(currentAccountGuid: UUID): Observable<CompanySectionDataset> {
    return this.getInvoicesSummary$(currentAccountGuid)
      .pipe(
        switchMap((invoiceSummary) => this.getAccounts$.pipe(
          tap((accounts) => {
            this.setCurrentAccountGuid(currentAccountGuid);
            this.setAccounts(accounts);
          }),
          map((accounts) => ({
            accounts,
            invoiceSummary
          }))
        ))
      );
  }

  public refreshAccounts$(): Observable<Account[]> {
    this.refreshAccounts.next();
    return this.getAccounts$.pipe(
      tap((accounts) => this.setAccounts(accounts))
    );
  }

  public readonly getAccounts$ = this.refreshAccounts.pipe(
    switchMap(() => this.http.get<Account[]>(`${environment.apiUrl}/account`)),
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: false
    })
  );

  private getInvoicesSummary$(currentAccountGuid: UUID): Observable<InvoicesSummary> {
    return this.http.get<InvoicesSummary>(`${environment.apiUrl}/account/${currentAccountGuid}/invoice-summary`)
      .pipe(
        tap((invoiceSummary) => this.setInvoiceSummary(invoiceSummary))
      );
  }

  public postConfirmCompanyManager$ = (accountGuid: UUID): Observable<string> => {
    return this.http.post<string>(`${environment.apiUrl}/account/${accountGuid}/confirm-manager`, {});
  };

  public readonly putAccount$ = (guid: UUID, invoiceMail: Email): Observable<never> => {
    return this.http.put<never>(
      `${environment.apiUrl}/account/${guid}`,
      { sendRSIEmail: invoiceMail }
    );
  };
}
