import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CustomMaterialModule } from '@shared/modules/material/custom-material.module';
import { RentaNgxLibModule } from '@lib/rs-ngx';
import { CommonModule } from '@angular/common';
import { ProfileCardComponent } from '@shared/components/profile-card/profile-card.component';
import { PlatformChipComponent } from './components/platform-chip/platform-chip.component';
import { UserProfileFormComponent } from '@shared/components/user-profile-form/user-profile-form.component';
import { CompanyCardComponent } from '@shared/components/company-card/company-card.component';

/** @deprecated Usage will change, please read description below!!!
 *
 * @description What!!! How should we use it now ???
 *
 * - Will be used only to import/export real shared modules like CommonModule, TranslateModule.forChild (Those will be shared only in rentaNgxModule)
 * - For other modules like Material one's, please ONLY import the ones you need where you need them!
 * - For shared components, please create standalone ones and import it only where needed
 * - Same for shared directives, and pipes, please create standalone ones and import it only where needed
 **/
@NgModule({
  declarations: [ProfileCardComponent],
  imports: [
    CommonModule,
    RentaNgxLibModule,
    PlatformChipComponent,
    CustomMaterialModule,
    CompanyCardComponent,
    UserProfileFormComponent
  ],
  exports: [
    CommonModule,
    RentaNgxLibModule,
    CustomMaterialModule,
    ProfileCardComponent,
    UserProfileFormComponent,
    CompanyCardComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule {
}
