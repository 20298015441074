import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDetails } from '@shared/models/user-details';
import { Store } from '@ngrx/store';
import { userDetails } from '@users/state/users.selector';
import { Observable, Subject } from 'rxjs';
import { myUserDetails } from '../../../my-profile-and-settings/state/my-profile-and-settings.selector';

@Component({
  selector: 'mgt-user-profile-box',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  standalone: false
})
export class ProfileCardComponent implements OnInit {

  @Input() public thumbnail = true;
  @Input() public lastLoginData?: string;
  @Input() public isMyUser?: boolean;

  public destroy$: Subject<boolean> = new Subject();
  public profile$: Observable<UserDetails> = new Observable();

  public constructor
  (
    private readonly translateService: TranslateService,
    private readonly store: Store
  ) {
  }

  public get currentLang(): string {
    return this.translateService.currentLang;
  }

  public ngOnInit(): void {
    this.profile$ = this.store.select(this.isMyUser ? myUserDetails : userDetails);
  }


}
